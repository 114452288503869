.u-centered-content {
  display: flex;
}

.popup-custom-margin {
  margin-top: 5vw;
}

.data-amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  min-height: 85vh;
}

.admin-dashboard-container {
  display: flex;
  flex-direction: column;
}

.data-amplify-authenticator div[data-amplify-container] {
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
}

.data-amplify-authenticator div {
  border-radius: 0.5vw;
}

.amplify-tabs button {
  border-radius: 0.5vw;
}

.amplify-tabs-item[data-state="active"] {
  color: orange;
  border-color: orange;
}

.amplify-tabs-item[data-state]:not([data-state="active"]) {
  color: darkgray;
  font-weight: 400;
}

.amplify-button[data-variation="primary"]:hover {
  background-color: cornflowerblue;
}

.amplify-button {
  --amplify-components-button-primary-background-color: orange;
  --amplify-components-button-link-color: orange;
}

.table {
  margin-bottom: 0px !important;
  padding: 1vw !important;
}

.table > :not(:first-child) {
  border-top: 0.1vw solid currentColor !important;
}

.table-bordered > :not(caption) > * > * {
  padding: 0.5vw !important;
  border-width: 0 0.05vw !important;
}

.table-bordered > :not(caption) > * {
  border-width: 0.05vw 0 !important;
}

@media screen and (max-width: 992px) {
  .popup-custom-margin {
    margin-top: 4vw;
  }
}
