/* TODO: vwove Buttons Section */

.orders-dashboard-buttons-section {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  padding: 0.2vw;
  margin: 0.5vw;
  display: none;
}

.orders-dashboard-temp {
  display: flex;
  flex-direction: column;
}

.orders-dashboard-container {
  display: flex;
  flex-direction: column;
  width: 97vw;
  max-width: 97vw;
}

.orders-dashboard-section {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}

.orders-dashboard-container-empty {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  min-height: 80vh;
}

.orders-dashboard-empty-section {
  display: flex;
  justify-content: center;
  padding: 4vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.orders-dashboard-empty-label {
  padding: 1vw;
  font-size: 2.4vw;
}
